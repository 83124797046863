import axios from 'axios';

const API_URL = 'https://api.storyface.ai/api';

const authProvider = {
    login: async ({ username, password }) => {
        try {
            const { data, status } = await axios.post(`${API_URL}/auth/login`, {
                email: username,
                password,
            });

            if (status >= 200 && status < 300) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken); // Assuming refreshToken is returned
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            throw new Error(error.response?.statusText || 'An error occurred during login');
        }
    },

    logout: () => {
        ['token', 'refreshToken'].forEach(item => localStorage.removeItem(item));
        return Promise.resolve();
    },

    checkError: async (error) => {
        if (error.status === 401) {
            const refreshToken = localStorage.getItem('refreshToken');

            if (!refreshToken) {
                return Promise.reject();
            }

            try {
                const { data } = await axios.post(`${API_URL}/auth/refresh`, { refreshToken });

                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken); // Update the refresh token
                return Promise.resolve();
            } catch {
                authProvider.logout();
                return Promise.reject();
            }
        }

        return Promise.resolve();
    },

    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },

    getPermissions: () => Promise.resolve(),
};

export default authProvider;

